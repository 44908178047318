import React, { useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingPopup from '../components/popups/LoadingPopup';
import Notifications from '../components/Notifications';
import MelodySamplesUploadPopup from '../components/popups/melodySamplesUpload'; // Adjust the path if necessary
import { displayError, displaySuccess } from '../components/utils';
import { FaFileAudio, FaTimes, FaQuestionCircle, FaPlay, FaPause, FaInfoCircle } from 'react-icons/fa';
import { authFetch } from '../authFetch';

// Add field info content
const fieldInfo = {
    instruments: {
        title: "Instruments",
        description: "List the instruments used in this melody sample.",
        examples: "Examples: Piano, Guitar, Synth, Strings, Bass, etc."
    },
    genres: {
        title: "Genres",
        description: "List the musical genres this melody fits into.",
        examples: "Examples: Hip Hop, R&B, Pop, Trap, Lo-fi, etc."
    },
    artists: {
        title: "Similar Artists",
        description: "Reference artists whose style matches this melody.",
        examples: "Examples: Metro Boomin, The Weeknd, Drake, etc."
    },
    moods: {
        title: "Moods",
        description: "Describe the emotional qualities or atmosphere of the melody.",
        examples: "Examples: Dark, Uplifting, Melancholic, Energetic, etc."
    },
    tempoStyle: {
        title: "Tempo Style",
        description: "Indicate the tempo range or style this melody works best with.",
        examples: "Examples: Slow, Medium, Fast, Half-time, Double-time, etc."
    },
    bpm: {
        title: "BPM",
        description: "The tempo of the melody in beats per minute.",
        examples: "Examples: 140, 160, 80, etc."
    },
    keyMode: {
        title: "Key/Mode",
        description: "The musical key and mode of the melody.",
        examples: "Examples: C Major, F# Minor, etc."
    }
};

// Add InfoTooltip component
const InfoTooltip = ({ info }) => {
    return (
        <div className="dropdown dropdown-hover dropdown-end inline-block ml-1">
            <label tabIndex={0} className="text-base-content/50 hover:text-primary cursor-help">
                <FaInfoCircle className="inline-block w-4 h-4" />
            </label>
            <div tabIndex={0} className="dropdown-content z-[1] card card-compact w-64 p-2 shadow bg-base-100 border border-base-300">
                <div className="card-body p-3">
                    <h3 className="font-bold text-sm">{info.title}</h3>
                    <p className="text-xs text-base-content/70">{info.description}</p>
                    <p className="text-xs text-base-content/70 mt-1">{info.examples}</p>
                </div>
            </div>
        </div>
    );
};

// Update TagInput component to show input instructions
const TagInput = ({ label, placeholder, tags, onKeyUp, onRemove, info }) => (
    <div className="form-control w-full">
        <label className="label">
            <span className="label-text flex items-center">
                {label}
                <InfoTooltip info={info} />
            </span>
        </label>
        <div className="relative">
            <input
                type="text"
                placeholder={placeholder}
                className="input input-bordered w-full pr-24"
                onKeyUp={onKeyUp}
            />
            <div className="absolute right-3 top-1/2 -translate-y-1/2 text-xs text-base-content/50">
                Press Enter or ,
            </div>
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
            {tags.map((tag, index) => (
                <span key={index} className="badge badge-primary gap-1">
                    {tag}
                    <button
                        onClick={() => onRemove(index)}
                        className="btn btn-xs btn-ghost btn-circle"
                    >
                        ×
                    </button>
                </span>
            ))}
        </div>
    </div>
);

const SamplesUpload = () => {
  const { getIdTokenClaims } = useAuth0();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [instruments, setInstruments] = useState([]);
  const [artists, setArtists] = useState([]);
  const [genres, setGenres] = useState([]);
  const [moods, setMoods] = useState([]);
  const [tempoStyle, setTempoStyle] = useState([]);
  const [bpm, setBpm] = useState('');
  const [key, setKey] = useState('');
  const [mode, setMode] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [errorMessages, setErrorMessages] = useState('');
  const [successMessages, setSuccessMessages] = useState('');
  //Playing File State
  const [playingFile, setPlayingFile] = useState(null);
  const audioRef = useRef(null);
  const [showNotice, setShowNotice] = useState(true);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const wavFiles = files.filter(file => file.type === 'audio/wav');

    if (wavFiles.length !== files.length) {
      setErrorMessage('Only WAV files are allowed.');
    } else {
      setErrorMessage('');
      setSelectedFiles(prevFiles => [...prevFiles, ...wavFiles.map(file => ({
        file,
        url: URL.createObjectURL(file)
      }))]);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const wavFiles = files.filter(file => file.type === 'audio/wav');

    if (wavFiles.length !== files.length) {
      setErrorMessage('Only WAV files are allowed.');
    } else {
      setErrorMessage('');
      setSelectedFiles(prevFiles => [...prevFiles, ...wavFiles.map(file => ({
        file,
        url: URL.createObjectURL(file)
      }))]);
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);
    if (playingFile === index) {
      setPlayingFile(null);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  };

  const handlePlayPause = (index) => {
    if (playingFile === index) {
      audioRef.current.pause();
      setPlayingFile(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      audioRef.current.src = selectedFiles[index].url;
      audioRef.current.play();
      setPlayingFile(index);
    }
  };

  const handleTagChange = (e, setter, tagArray) => {
    if (e.key === 'Enter' || e.key === ',') {
      const value = e.target.value.replace(/,$/, '').trim();
      if (value && !tagArray.includes(value)) {
        setter([...tagArray, value]);
      }
      e.target.value = '';
      e.preventDefault();
    }
  };

  const handleRemoveTag = (setter, tagArray, index) => {
    const newArray = tagArray.filter((_, i) => i !== index);
    setter(newArray);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = (e) => {
    if (e.target.id !== 'file') {
      document.getElementById('file').click();
    }
  };

  const handleBpmChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setBpm(value);
    }
  };


  const handleHowToUploadClick = () => {
    setIsPopupOpen(true); // Open the popup
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };


  const handleSubmit = async () => {
    setIsLoading(true);
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    // Convert the files to base64 strings
    const filesData = await Promise.all(
      selectedFiles.map(fileObj => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve({ name: fileObj.file.name, data: reader.result });
          reader.onerror = error => reject(error);
          reader.readAsDataURL(fileObj.file);
        });
      })
    );

    const payload = {
      files: filesData,
      instruments,
      artists,
      genres,
      moods,
      tempo_style: tempoStyle,
      bpm,
      key,
      mode
    };

    try {
      const responseData = await authFetch(
        `${process.env.REACT_APP_BACKEND_URL}/uploadingmelsamples`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        getAccessTokenSilently
      );

      console.log('Response:', responseData);
      displaySuccess(setSuccessMessages)('Files uploaded successfully!');
    } catch (error) {
      console.error('Error:', error);
      if (error.status === 403 || error.status === 401) {
        // Handle unauthorized access
        displayError(setErrorMessages)('Unauthorized access. Please log in again.');
      } else {
        displayError(setErrorMessages)('Failed to upload files. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-base-200 py-12">
      <Notifications
        errorMessages={errorMessages}
        successMessages={successMessages}
        setErrorMessages={setErrorMessages}
        setSuccessMessages={setSuccessMessages}
      />

      <div className="container mx-auto px-4 max-w-4xl">
        {/* Title Section */}
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold mb-2">Upload Melody Samples</h1>
          <p className="text-base-content/70">Contribute and earn from it</p>
        </div>

        {/* Warning Notice */}
        {showNotice && (
          <div className="alert alert-info shadow-xl mb-8 bg-blue-500/10 border-l-4 border-blue-500">
            <div className="flex items-start justify-between w-full">
              <div className="flex items-start">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
                     className="stroke-current shrink-0 w-6 h-6 mt-1">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <div className="ml-3">
                  <h3 className="font-bold text-lg mb-2">Upload Guidelines</h3>
                  <div className="space-y-2">
                    <p>Upload individual <strong>melody stems</strong> only. Each upload represents one composition.</p>
                    <div className="grid grid-cols-2 gap-4 mt-2">
                      <div>
                        <h4 className="font-semibold text-success">✓ Accepted</h4>
                        <ul className="list-disc list-inside text-sm">
                          <li>Melody stems from compositions</li>
                          <li>Short melodic loops</li>
                          <li>Individual instrument melodies</li>
                          <li>Stems from a MP3 loop sample</li>
                        </ul>
                      </div>
                      <div>
                        <h4 className="font-semibold text-error">✗ Not Accepted</h4>
                        <ul className="list-disc list-inside text-sm">
                          <li>Full beats/songs</li>
                          <li>Drum loops</li>
                          <li>Vocal recordings</li>
                          <li>MP3 Sample 'Loops'</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button onClick={() => setShowNotice(false)} 
                      className="btn btn-circle btn-ghost btn-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" 
                     viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        )}

        {/* Collapsible Metadata Instructions */}
        <div className="collapse collapse-arrow bg-base-100 shadow-xl mb-6">
            <input type="checkbox" className="peer" /> 
            <div className="collapse-title text-lg font-medium flex items-center gap-2">
                <FaInfoCircle className="text-primary" />
                How to Add Metadata
                <span className="text-xs text-base-content/50 ml-2">(Click to expand)</span>
            </div>
            <div className="collapse-content">
                <div className="space-y-4 pt-2">
                    <div className="alert alert-info bg-info/10">
                        <div>
                            <p className="font-semibold">Important:</p>
                            <p>All metadata fields are required for a successful upload. This helps others find and use your samples effectively.</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <h4 className="font-semibold mb-2">Adding Tags:</h4>
                            <ul className="list-disc list-inside space-y-1 text-sm">
                                <li>Type your tag in the input field</li>
                                <li>Press <span className="badge badge-sm">Enter</span> or type a <span className="badge badge-sm">,</span> (comma) to add it</li>
                                <li>Each tag will appear as a badge below the input</li>
                                <li>Click the × on a badge to remove it</li>
                            </ul>
                        </div>
                        <div>
                            <h4 className="font-semibold mb-2">Required Fields:</h4>
                            <ul className="list-disc list-inside space-y-1 text-sm">
                                <li>At least one file must be uploaded</li>
                                <li>All tag fields need at least one entry</li>
                                <li>BPM must be a number</li>
                                <li>Key and Mode must be selected</li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-sm text-base-content/70">
                        <p>💡 Tip: Hover over the (i) icon next to each field for examples and descriptions of what to enter.</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Main Upload Card */}
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body space-y-6">
            {/* File Upload Zone */}
            <div className="upload-zone">
              <div
                className="border-2 border-dashed border-primary/50 rounded-lg p-8 text-center cursor-pointer hover:border-primary transition-colors"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={handleClick}
              >
                <input
                  type="file"
                  id="file"
                  multiple
                  accept=".wav"
                  className="hidden"
                  onChange={handleFileChange}
                />
                <div className="flex flex-col items-center gap-4">
                  <FaFileAudio className="w-12 h-12 text-primary/70" />
                  <div>
                    <p className="font-medium">Drag and drop WAV files here</p>
                    <p className="text-sm text-base-content/70">or click to select files</p>
                  </div>
                </div>
              </div>

              {/* File Preview List */}
              {selectedFiles.length > 0 && (
                <div className="mt-4 space-y-2">
                  {selectedFiles.map(({ file }, index) => (
                    <div key={index} 
                         className="flex items-center justify-between p-3 bg-base-200 rounded-lg">
                      <div className="flex items-center gap-3">
                        <FaFileAudio className="text-primary" />
                        <span className="font-medium">{file.name}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          className="btn btn-circle btn-sm btn-ghost"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePlayPause(index);
                          }}
                        >
                          {playingFile === index ? <FaPause /> : <FaPlay />}
                        </button>
                        <button
                          className="btn btn-circle btn-sm btn-ghost text-error"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveFile(index);
                          }}
                        >
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Metadata Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Tags Section */}
              <div className="space-y-4">
                <TagInput
                  label="Artists"
                  placeholder="Add similar artists (press Enter)"
                  tags={artists}
                  onKeyUp={(e) => handleTagChange(e, setArtists, artists)}
                  onRemove={(index) => handleRemoveTag(setArtists, artists, index)}
                  info={fieldInfo.artists}
                />
                <TagInput
                  label="Instruments"
                  placeholder="Add instruments (press Enter)"
                  tags={instruments}
                  onKeyUp={(e) => handleTagChange(e, setInstruments, instruments)}
                  onRemove={(index) => handleRemoveTag(setInstruments, instruments, index)}
                  info={fieldInfo.instruments}
                />
                <TagInput
                  label="Genres"
                  placeholder="Add genres (press Enter)"
                  tags={genres}
                  onKeyUp={(e) => handleTagChange(e, setGenres, genres)}
                  onRemove={(index) => handleRemoveTag(setGenres, genres, index)}
                  info={fieldInfo.genres}
                />
              </div>

              <div className="space-y-4">
                <TagInput
                  label="Moods"
                  placeholder="Add moods (press Enter)"
                  tags={moods}
                  onKeyUp={(e) => handleTagChange(e, setMoods, moods)}
                  onRemove={(index) => handleRemoveTag(setMoods, moods, index)}
                  info={fieldInfo.moods}
                />
                <TagInput
                  label="Tempo Style"
                  placeholder="Add tempo styles (press Enter)"
                  tags={tempoStyle}
                  onKeyUp={(e) => handleTagChange(e, setTempoStyle, tempoStyle)}
                  onRemove={(index) => handleRemoveTag(setTempoStyle, tempoStyle, index)}
                  info={fieldInfo.tempoStyle}
                />
              </div>
            </div>

            {/* Technical Details */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="form-control">
                <label className="label">
                  <span className="label-text flex items-center">
                    BPM
                    <InfoTooltip info={fieldInfo.bpm} />
                  </span>
                </label>
                <input
                  type="text"
                  className={`input input-bordered ${!bpm ? 'input-error' : ''}`}
                  value={bpm}
                  onChange={handleBpmChange}
                  placeholder="Enter BPM"
                />
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text flex items-center">
                    Key
                    <InfoTooltip info={fieldInfo.keyMode} />
                  </span>
                </label>
                <select
                  className={`select select-bordered ${!key ? 'select-error' : ''}`}
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                >
                  <option value="">Select Key</option>
                  {['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'].map(note => (
                    <option key={note} value={note}>{note}</option>
                  ))}
                </select>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">
                    Mode <span className="text-error">*</span>
                  </span>
                  {!mode && <span className="label-text-alt text-error">Required</span>}
                </label>
                <select
                  className={`select select-bordered ${!mode ? 'select-error' : ''}`}
                  value={mode}
                  onChange={(e) => setMode(e.target.value)}
                >
                  <option value="">Select Mode</option>
                  <option value="Major">Major</option>
                  <option value="Minor">Minor</option>
                </select>
              </div>
            </div>

            {/* Submit Button */}
            <button
              className="btn btn-primary w-full"
              onClick={handleSubmit}
              disabled={
                !bpm || 
                !artists.length || 
                !instruments.length || 
                !genres.length || 
                !moods.length || 
                !tempoStyle.length || 
                !key || 
                !mode || 
                selectedFiles.length === 0
              }
            >
              {!selectedFiles.length ? 'Select Files to Upload' : 
               !artists.length ? 'Add Artists' :
               !instruments.length ? 'Add Instruments' :
               !genres.length ? 'Add Genres' :
               !moods.length ? 'Add Moods' :
               !tempoStyle.length ? 'Add Tempo Style' :
               !bpm ? 'Add BPM' :
               !key ? 'Select Key' :
               !mode ? 'Select Mode' :
               'Upload Samples'}
            </button>
          </div>
        </div>
      </div>

      {/* Audio player */}
      <audio ref={audioRef} onEnded={() => setPlayingFile(null)} />
      
      {/* Popups */}
      <LoadingPopup isOpen={isLoading} />
      <MelodySamplesUploadPopup isOpen={isPopupOpen} onClose={handleClosePopup} />
    </div>
  );
};

export default SamplesUpload;