import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../components/SubscriptionFeatures.css'; // Link to the CSS file

const subscriptionOptions = [
    {
        type: 'Free Access',
        price: 'Free',
        features: [
            'Get 25 tokens a month!',
            'Able to test features with limited access',
            'Able to track your generated music',
            'Standard Support Access'
        ],
        buttonText: 'Subscribe'
    },
    {
        type: 'Early Access Premium',
        price: '$7.50 USD per month',
        features: [
            'Get 100 tokens a month!',
            'Signup Ends on March 1st',
            'Early adopter discount! Lasting 1 whole year. Saving you 62.5% off!',
            'Access to all premium features for a year!',
        ],
        buttonText: 'Subscribe'
    },
    {
        type: 'Premium',
        price: '$20.00 USD per month',
        features: [
            'Get 100 tokens a month!',
            'Edit generated beats',
            'Generate full beats',
            'Ability to purchase beats',
            'Ability to upload stems to earn money',
            'Priotity support',
            'Access to all new and upcoming features',
            
        ],
        buttonText: 'Subscribe'
    },
    {
        type: 'Enterprise',
        price: '$500.00 USD per month',
        features: [
            'Get 1,000 tokens a month!',
            'Instant support access',
            'Required for Record Labels, Publishers, and Businesses',
            'Access to all new and upcoming features',
            'First hand knowledge of new features and ability to provide feedback',
            'Ability to request features',
            'Copyright & publishing auditing support'
        ],
        buttonText: 'Subscribe'
    }
    // Add more subscription options here
];

const SubscriptionFeature = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    // Function to handle button click
    const navigateToSubscriptionOptions = () => {
        navigate('/subscriptions');
    };

    return (
        <div className="subscription-feature">
            <h1 className="text-4xl font-bold text-center mb-8 text-primary">Our Plans</h1>
            <div className="membership-cards">
                {subscriptionOptions.map(option => (
                    <div key={option.type} className="membership-card">
                        <h2>{option.type}</h2>
                        <h3>{option.price}</h3>
                        <ul className="features-list">
                            {option.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <br/>
            <button className="sign-up-button" onClick={navigateToSubscriptionOptions}>Sign Up Now</button>
        </div>
    );
};

export default SubscriptionFeature;
