import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import Notifications from './Notifications';
import { useAuth0 } from '@auth0/auth0-react';
import RightsPopup from './RightsPopup'; // Make sure to import the RightsPopup component
import fetchLeaseOptions from './fetchLeaseOptions'; // Import the fetchLeaseOptions function
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo, faDownload, faShoppingCart, faVolumeUp, faGem } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import { loadStripe } from '@stripe/stripe-js';
import { displayError, displaySuccess } from './utils';
import './BeatPlayer.css';

function WaveformPlayer({ beat_id, audio_url, Beatkey, mode, bpm, beatTitle, beatStructureLabels }) {
    const [errorMessages, setErrorMessages] = useState(''); // Initialize error message state

    const { getIdTokenClaims } = useAuth0();
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioReady, setAudioReady] = useState(false);
    const [volume, setVolume] = useState(1); // New state for volume
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [userInfo, setUserInfo] = useState({
        fname: '',
        lname: '',
        country: '',
      });
    const [isInfoComplete, setIsInfoComplete] = useState(false);

    const downloadBeat = () => {
        if (!audio_url) {
            alert('No beat available for download.');
            return;
        }
    
        fetch(audio_url)
            .then(response => response.blob())
            .then(blob => {
                // Use file-saver to save the blob as a file
                //IDK if it should be mp3... its technically a WAV file.
                //Set save as to BeatTitle
                saveAs(blob, beatTitle); // You can specify a filename here
            })
            .catch(e => {
                console.error("Download failed", e);
                alert("Failed to download the beat.");
            });
    };

    useEffect(() => {
        // Fetch user info from the backend to check if it's complete
        const fetchUserInfo = async () => {
            try {
                const idTokenClaims = await getIdTokenClaims();
                const idToken = idTokenClaims.__raw;
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-info`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${idToken}`,
                    },
                    credentials: 'include',
                });
                const data = await response.json();
                setUserInfo({
                    fname: data.first_name,
                    lname: data.last_name,
                    country: data.country,
                });
                setIsInfoComplete(data.first_name && data.last_name && data.country);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, []);
    // Need to finish this function
    // Purchase Beat
    const [showRightsPopup, setShowRightsPopup] = useState(false);
    const [leaseOptions, setLeaseOptions] = useState([]);


    useEffect(() => {
        const loadLeaseOptions = async () => {
            const options = await fetchLeaseOptions(beat_id);
            setLeaseOptions(options);
        };
        loadLeaseOptions();
    }, [beat_id]);

    useEffect(() => {
        if (wavesurfer.current) {
            wavesurfer.current.setVolume(volume);
        }
    }, [volume]);

    useEffect(() => {
        const volumeSlider = document.querySelector('.volume-control input[type="range"]');
        if (volumeSlider) {
            volumeSlider.addEventListener('touchstart', handleVolumeChange);
            volumeSlider.addEventListener('touchmove', handleVolumeChange);
            return () => {
                volumeSlider.removeEventListener('touchstart', handleVolumeChange);
                volumeSlider.removeEventListener('touchmove', handleVolumeChange);
            };
        }
    }, []);

    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        setVolume(newVolume);
        if (wavesurfer.current) {
            wavesurfer.current.setVolume(newVolume);
        }
    };

    const getVolumeGradient = () => {
        const percentage = volume * 100;
        return `linear-gradient(to right, 
            #D9DCFF 0%, 
            #B1B8FF ${percentage * 0.3}%, 
            #7B83FF ${percentage * 0.6}%, 
            #4353FF ${percentage}%, 
            #e0e0e0 ${percentage}%, 
            #e0e0e0 100%)`;
    };

    useEffect(() => {
        wavesurfer.current = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#D9DCFF',
            progressColor: '#4353FF',
            cursorColor: '#4353FF',
            barWidth: 2,
            barGap: 2,
            barRadius: 3,
            height: 80,
            responsive: true,
            normalize: true,
            partialRender: true,
            pixelRatio: 1
        });

        wavesurfer.current.load(audio_url);

        // Event listeners
        wavesurfer.current.on('play', () => setIsPlaying(true));
        wavesurfer.current.on('pause', () => setIsPlaying(false));
        wavesurfer.current.on('stop', () => setIsPlaying(false));
        wavesurfer.current.on('ready', () => {
            setAudioReady(true);
            setTotalDuration(wavesurfer.current.getDuration());
        });
        wavesurfer.current.on('audioprocess', (time) => {
            setCurrentTime(time);
        });

        return () => {
            wavesurfer.current.destroy();
        };
    }, [audio_url]);

    const handlePlayPause = () => {
        if (!audioReady) {
            alert('Beat is not ready yet. Wait 1 moment.');
            return;
        }
        if (isPlaying) {
            wavesurfer.current.pause();
        } else {
            wavesurfer.current.play().catch(error => {
                alert("Error playing audio. Please try again.");
                console.error("Error playing audio:", error);
            });
        }
    };
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const handleRestart = () => {
        if (wavesurfer.current) {
            wavesurfer.current.seekTo(0); // Seek to the start of the track
            if (isPlaying) {
                wavesurfer.current.play(); // Play if it was already playing
            }
        }
    };


    const handlePurchase = async (priceId) => {
        try {
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            credentials: 'include',
            body: JSON.stringify({
              beat_id: beat_id,
              lease_option_id: priceId,
              first_name: userInfo.fname,
              last_name: userInfo.lname,
              country: userInfo.country,
            }),
          });
          const session = await response.json();
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
          stripe.redirectToCheckout({ sessionId: session.id });
        } catch (error) {
          console.error('Error creating checkout session:', error);
        }
      };

      const handleGetLicensingInfo = () => {
        if (isInfoComplete) {
            setShowRightsPopup(true);
        } else {
            displayError(setErrorMessages)('Please complete your profile information to proceed.');
        }
    };

    return (
        <div className="beat-player">
            <div className="beat-title">
                <h2>{beatTitle}</h2>
            </div>
            
            <div className="waveform">
                <div className="time-display">
                    {formatTime(currentTime)} / {formatTime(totalDuration)}
                </div>
                <div ref={waveformRef}></div>
            </div>
            
            <div className="player-controls">
                <div className="controls-row">
                    <button 
                        className="control-button restart"
                        onClick={handleRestart} 
                        disabled={!audioReady}
                    >
                        <FontAwesomeIcon icon={faRedo} />
                    </button>
                    
                    <button 
                        className="control-button play"
                        onClick={handlePlayPause} 
                        disabled={!audioReady}
                    >
                        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                    </button>
                    
                    <div className="volume-slider">
                        <FontAwesomeIcon icon={faVolumeUp} />
                        <input
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={volume}
                            onInput={handleVolumeChange}
                            className="slider"
                            style={{ background: getVolumeGradient() }}
                        />
                        <span className="volume-value">{Math.round(volume * 100)}%</span>
                    </div>
                </div>
            </div>

            <div className="beat-info">
                <div className="info-item">
                    <span>Key</span>
                    <strong>{Beatkey}</strong>
                </div>
                <div className="info-item">
                    <span>Mode</span>
                    <strong>{mode}</strong>
                </div>
                <div className="info-item">
                    <span>BPM</span>
                    <strong>{bpm}</strong>
                </div>
            </div>

            <div className="beat-structure">
                <span>Structure</span>
                <strong>{beatStructureLabels}</strong>
            </div>

            <div className="action-buttons">
                {audio_url && (
                    <button className="action-button download" onClick={downloadBeat}>
                        <FontAwesomeIcon icon={faDownload} />
                        Download
                    </button>
                )}
                
                <button 
                    className="action-button license"
                    onClick={handleGetLicensingInfo}
                >
                    <FontAwesomeIcon icon={faGem} />
                    Get License
                </button>
            </div>

            <Notifications
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
                displayError={displayError}
            />
            
            <RightsPopup
                isOpen={showRightsPopup}
                onClose={() => setShowRightsPopup(false)}
                leaseOptions={leaseOptions}
                onPurchase={handlePurchase}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                getIdTokenClaims={getIdTokenClaims}
            />
        </div>
    );
}

export default WaveformPlayer;
