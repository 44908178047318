import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import Notifications from '../components/Notifications';
import { displayError, displaySuccess } from '../components/utils';

const countryList = [
    'United States', 'Canada', 'United Kingdom', 'Australia', 'Germany',
    'France', 'India', 'China', 'Japan', 'South Korea', 'Mexico', 'Brazil',
    'Russia', 'Italy', 'Spain', 'Netherlands', 'Turkey', 'Switzerland',
    'Sweden', 'Belgium', 'Austria', 'Denmark', 'Norway', 'Finland', 'Ireland'
    // Add more countries as needed
  ];

const AccountPage = ({ handleRegenerateBeat }) => {
    const navigate = useNavigate(); // Initialize useNavigate
    const [errorMessages, setErrorMessages] = useState(''); // Initialize error message state
    const [successMessages, setSuccessMessages] = useState(''); // Initialize success message state
    const [hasStripeAccount, setHasStripeAccount] = useState(false);
    const { getIdTokenClaims, isAuthenticated, logout, loginWithRedirect } = useAuth0();
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(true);
    const [showEmail, setShowEmail] = useState(false); 

    const [profile, setProfile] = useState({
        username: '',
        email: '',
        userType: '',
        beats: [],
        creatorName: '',
        image: null,
        image_path: '',
        fname: '',
        lname: '',
        country: ''
      });

    
    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated) {
                await fetchProfileData();
                await checkStripeAccount();
            }
            setShowLoadingOverlay(false); // Hide overlay once data is loaded

        };
        fetchData();
    }, [isAuthenticated]);


    const checkStripeAccount = async () => {
        console.log('Checking Stripe account...');

        try {
            const idTokenClaims = await getIdTokenClaims();
            const idToken = idTokenClaims.__raw;

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-stripe-account`, {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setHasStripeAccount(data.hasStripeAccount);
            }
        } catch (error) {
            console.error('Error checking Stripe account:', error);
        }
    };
    
    const handleStripeAccountClick = async () => {
        try {
            const idTokenClaims = await getIdTokenClaims();
            const idToken = idTokenClaims.__raw;

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/express-dashboard`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                window.location.href = data.url;
            } else {
                console.error('Error accessing Stripe account:', response.statusText);
            }
        } catch (error) {
            console.error('Error accessing Stripe account:', error);
        }
    };
    // Function to navigate to Regernerate page
    const navigateToBeatEditPage = async (beatId) => {
        navigate('/'); // Navigate back to the main page
        await handleRegenerateBeat(beatId); // Wait for the regeneration process to complete
    };

    const fetchProfileData = async () => {
        const idTokenJson = await getIdTokenClaims();
        const idToken = idTokenJson.__raw;
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/account/profile`, {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
                credentials: 'include',
            });
            //console.log(`${process.env.REACT_APP_BACKEND_URL}/account/profile`);

            if (response.ok) {
                const data = await response.json();
                // Update the state with the fetched data
                setProfile(prevState => ({
                    ...prevState, 
                    ...data, 
                    userType: data.user_type, // Make sure to correctly map these fields
                    creatorName: data.creator_name,
                    fname: data.fname,
                    lname: data.lname,
                    country: data.country
                }));
                setShowEmail(true);
            } else if (response.status === 401) {
                const data = await response.json();
                console.error('Session expired or invalid. Please log in again.');
                loginWithRedirect();
                alert(data.message);
                if (data.redirect) {
                    window.location.href = data.redirect;
                } else {
                    logout();
                }
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };
    

    const handleCreatorNameChange = (event) => {
        setProfile({
            ...profile,
            creatorName: event.target.value
        });
    };
    // const handleFirstNameChange = (event) => {
    //     setProfile({
    //       ...profile,
    //       fname: event.target.value
    //     });
    //   };
    
    //   const handleLastNameChange = (event) => {
    //     setProfile({
    //       ...profile,
    //       lname: event.target.value
    //     });
    //   };
    
      const handleCountryChange = (event) => {  
        const selectedCountry = event.target.value; 
        const countryCode = countryList.find(country => country === selectedCountry); 
        setProfile({  
          ...profile,  
          country: countryCode,   
        });  
    };  
    
    useEffect(() => {
        fetchProfileData();
    }, []);

    // const handleUserTypeChange = (event) => {
    //     setProfile({
    //         ...profile,
    //         userType: event.target.value
    //     });
    // };

    const handleSaveProfile = async () => {
        const idToken = await getIdTokenClaims();
        const formData = new FormData();
        formData.append('userType', profile.userType);
        formData.append('creatorName', profile.creatorName);
        formData.append('fname', profile.fname);
        formData.append('lname', profile.lname);
        formData.append('country', profile.country);
        if (profile.image) {
            formData.append('image', profile.image);
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/account/profile`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
                credentials: 'include',
                body: formData
            });
    
            const responseData = await response.json();
            if (response.ok) {
                // Update the state with the new data after successful save
                setProfile(prevState => ({
                    ...prevState, 
                    userType: formData.get('userType'),
                    creatorName: formData.get('creatorName'),
                    fname: formData.get('fname'),
                    lname: formData.get('lname'),
                    country: formData.get('country')
                }));
                console.log("Profile updated successfully:", responseData);
                displaySuccess(setSuccessMessages)('Profile updated successfully.');
            }
        } catch (error) {
            console.error('Error saving profile:', error);
        }
    };

    // new function to cancel subscription
    const cancelSubscription = async () => {
        const idToken = await getIdTokenClaims();
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/cancel-subscription`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
            credentials: 'include',
          });
      
          if (response.ok) {
            if (response.status === 200) {
              setProfile((prevState) => ({
                ...prevState,
                subscription_type: 'free',
              }));
              console.log('Subscription cancelled successfully:', response);
              displaySuccess(setSuccessMessages)('Subscription cancelled successfully.');
            } else {
              const data = await response.json();
              console.log('Subscription failed to be cancelled:', data.error);
              displayError(setErrorMessages)(data.error);
            }
          } else {
            const data = await response.json();
            console.log('Subscription failed to be cancelled:', data.error);
            displayError(setErrorMessages)(data.error);
          }
        } catch (error) {
          console.error('Error cancelling subscription:', error);
          displayError(setErrorMessages)('Error cancelling subscription.');
        }
      };

      
    const handleProfilePicChange = (event) => {
        if (event.target.files[0]) {
            setProfile({ ...profile, image: event.target.files[0] });
        }
    };

    if (showLoadingOverlay || !showEmail) {
        return (
            <div className="loading-overlay">
                <div className="loading-spinner"></div>
                Please wait. Page is Loading...
            </div>
        );
    }

    return (
    <div className="account-page">
              <Notifications
        errorMessages={errorMessages}
        successMessages={successMessages}
        setErrorMessages={setErrorMessages}
        setSuccessMessages={setSuccessMessages}
        displayError={displayError}
        displaySuccess={displaySuccess}
                />
            <div className="account-details-box"> {/* New wrapper div */}
            <h2>My Account</h2>
            <div className="account-details">
<div className="profile-pic">
    <label htmlFor="profile-pic-upload" className="label">
        <span className="label-text"><strong>Profile Picture:</strong></span>
    </label>

    {profile.image_path && (
        <div className="avatar mt-2">
            <div className="w-24 rounded">
                <img src={profile.image_path} alt="Profile" />
            </div>
        </div>
    )}
        <div className="relative">
        <input id="profile-pic-upload" type="file" className="input input-bordered file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-600 cursor-pointer opacity-0 absolute w-full h-full left-0 top-0" onChange={handleProfilePicChange} />
        <button type="button" className="btn btn-primary" onClick={() => document.getElementById('profile-pic-upload').click()}>
            Change Picture
        </button>
    </div>
</div>
                <div className="profile-info">
                    <p><strong>Username: </strong> {profile.username}</p>
                    <p><strong>Subscription Type:</strong> {profile.subscription_type}</p>
                    <p><strong>Email: </strong>{profile.email}</p>
                    <div className="container mx-auto p-4">
                    <div className="container mx-auto p-4">
        <div className="mb-2">
            <label htmlFor="creatorName" className="block text-xs font-medium text-gray-700">Artist/Producer Name:</label>
            <input
                type="text"
                id="creatorName"
                value={profile.creatorName}
                onChange={handleCreatorNameChange}
                className="input input-bordered input-sm w-full mt-1"
            />
        </div>
        
        <div className="mb-2">
            <label htmlFor="fname" className="block text-xs font-medium text-gray-700">First Name:</label>
            <input
                type="text"
                id="fname"
                value={profile.fname}
                onChange={(e) => setProfile({ ...profile, fname: e.target.value })}
                className="input input-bordered input-sm w-full mt-1"
            />
        </div>
        
        <div className="mb-2">
            <label htmlFor="lname" className="block text-xs font-medium text-gray-700">Last Name:</label>
            <input
                type="text"
                id="lname"
                value={profile.lname}
                onChange={(e) => setProfile({ ...profile, lname: e.target.value })}
                className="input input-bordered input-sm w-full mt-1"
            />
        </div>
        
        <div className="mb-2">
        <label htmlFor="country" className="block text-xs font-medium text-gray-700">Country:</label>
            <select
                id="country"
                value={profile.country}
                onChange={handleCountryChange}
                className="select select-bordered select-sm w-full mt-1"
            >
                <option value="">Select a Country</option>
                {countryList.map((country, index) => (
                    <option key={index} value={country}>{country}</option>
                ))}
            </select>
        </div>
        
    </div>

                </div>


                    <br/>
                    <button className='btn bg-blue-400 hover:bg-blue-600 text-white' onClick={handleSaveProfile}>Save Changes</button>
                    {hasStripeAccount && (
                <button className='btn bg-blue-400 hover:bg-blue-600 text-white' onClick={handleStripeAccountClick}>Manage Stripe Account</button>
            )}     
                </div>
                <br/>
                    <button className="btn" onClick={cancelSubscription}>Cancel Subscription</button>
                <h3>Generated Beats</h3>
                <div className="beats-list">
                    <div className="beat-item-header">
                        <span>Search Query</span><span>Date Created</span><span>Producers</span>
                    </div>
                    {profile.beats
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by date, latest first
                        .slice(0, 200) // Limit to 200 entries
                        .map((beat, index) => (
                            <div key={index} className="beat-item" onClick={() => navigateToBeatEditPage(beat.beat_uuid)}>
                                <span>{beat.beat_name}</span><span>{beat.created_at}</span><span>{beat.producers}</span>
                            </div>
                        ))}
                </div>  
        </div>
        </div>
        </div>
    );
};

export default AccountPage;
