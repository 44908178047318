import React from 'react';
import { Link } from 'react-router-dom';

const LandingFooter = () => {
    return (
        <footer className="bg-base-200 text-base-content">
            <div className="container mx-auto py-12 px-6">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* Company Info */}
                    <div className="space-y-3">
                        <h3 className="text-lg font-bold mb-4">Your Studio Maestro</h3>
                        <p className="text-sm opacity-70">
                            Revolutionizing music production with AI technology and industry expertise.
                        </p>
                    </div>

                    {/* Quick Links */}
                    <div>
                        <h3 className="text-lg font-bold mb-4">Features</h3>
                        <ul className="space-y-2">
                            <li><Link to="/subscription-features" className="hover:text-primary">Subscription Features</Link></li>
                            <li><Link to="/beat-editing" className="hover:text-primary">Beat Editing Guide</Link></li>
                        </ul>
                    </div>

                    {/* Legal */}
                    <div>
                        <h3 className="text-lg font-bold mb-4">Legal</h3>
                        <ul className="space-y-2">
                            <li><Link to="/rights-usage" className="hover:text-primary">Rights & Usage</Link></li>
                            <li><Link to="/ysm-licenses" className="hover:text-primary">Licenses</Link></li>
                            <li><Link to="/terms-conditions" className="hover:text-primary">Terms & Conditions</Link></li>
                        </ul>
                    </div>

                    {/* About */}
                    <div>
                        <h3 className="text-lg font-bold mb-4">Company</h3>
                        <ul className="space-y-2">
                            <li><Link to="/about" className="hover:text-primary">About Our Team</Link></li>
                            <li><a href="mailto:contact@yourstudiomaestro.com" className="hover:text-primary">Contact Us</a></li>
                        </ul>
                    </div>
                </div>

                <div className="mt-12 pt-8 border-t border-base-300 text-center">
                    <p className="text-sm opacity-70">
                        © {new Date().getFullYear()} Your Studio Maestro. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default LandingFooter;