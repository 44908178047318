import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';

// Create a simple event bus for audio players
const audioEventBus = {
    currentPlayer: null,
    setCurrentPlayer(player) {
        if (this.currentPlayer && this.currentPlayer !== player) {
            this.currentPlayer.pause();
        }
        this.currentPlayer = player;
    }
};

function CompactWaveformPlayer({ audio_url, genre, title }) {
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioReady, setAudioReady] = useState(false);
    const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

    useEffect(() => {
        // Create WaveSurfer instance with mobile-friendly settings
        wavesurfer.current = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#D9DCFF',
            progressColor: '#4353FF',
            cursorColor: '#4353FF',
            barWidth: 2,
            barGap: 1,
            height: 60,
            responsive: true,
            normalize: true,
            minPxPerSec: 50,           // Controls waveform length
            fillParent: false,         // Allows scrolling
            scrollParent: true,
            autoCenter: true,          // Keeps playback centered
            backend: isMobile ? 'MediaElement' : 'WebAudio',
            mediaControls: false,
            hideScrollbar: true
        });

        // Force hide scrollbars after wavesurfer is created
        if (waveformRef.current) {
            waveformRef.current.style.overflow = 'hidden';
            const waveElement = waveformRef.current.querySelector('wave');
            if (waveElement) {
                waveElement.style.overflow = 'hidden';
            }
        }

        wavesurfer.current.load(audio_url);

        // Add audioprocess event to track playback
        wavesurfer.current.on('audioprocess', () => {
            const currentTime = wavesurfer.current.getCurrentTime();
            const scrollWidth = waveformRef.current.scrollWidth;
            const clientWidth = waveformRef.current.clientWidth;
            const scrollPosition = (currentTime / wavesurfer.current.getDuration()) * (scrollWidth - clientWidth);
            waveformRef.current.scrollLeft = scrollPosition;
        });

        wavesurfer.current.on('ready', () => {
            setAudioReady(true);
        });

        wavesurfer.current.on('play', () => {
            setIsPlaying(true);
            audioEventBus.setCurrentPlayer(wavesurfer.current);
        });
        
        wavesurfer.current.on('pause', () => setIsPlaying(false));

        return () => {
            if (audioEventBus.currentPlayer === wavesurfer.current) {
                audioEventBus.currentPlayer = null;
            }
            wavesurfer.current?.destroy();
        };
    }, [audio_url, isMobile]);

    const handlePlayPause = () => {
        if (!audioReady) return;
        
        if (isPlaying) {
            wavesurfer.current.pause();
        } else {
            wavesurfer.current.play().catch(error => {
                console.error("Error playing audio:", error);
            });
        }
    };

    const handleRestart = () => {
        if (!audioReady) return;
        
        wavesurfer.current.seekTo(0);
        if (isPlaying) {
            wavesurfer.current.play();
        }
    };

    return (
        <div className="card bg-base-100 shadow-xl">
            <div className="card-body p-4">
                <h3 className="card-title text-lg mb-2">{genre}</h3>
                <div className="flex flex-col gap-2">
                    <div 
                        ref={waveformRef} 
                        className="w-full" 
                        style={{
                            overflow: 'hidden',
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            width: '100%',
                            msOverflowStyle: 'none',  // IE and Edge
                            scrollbarWidth: 'none',   // Firefox
                            '&::-webkit-scrollbar': {
                                display: 'none'       // Chrome, Safari, and Opera
                            },
                            '& wave': {
                                overflow: 'hidden'
                            }
                        }}
                    ></div>
                    <div className="flex justify-center gap-2">
                        <button 
                            className="btn btn-circle btn-sm btn-outline btn-accent touch-manipulation"
                            onClick={handleRestart} 
                            disabled={!audioReady}
                        >
                            <FontAwesomeIcon icon={faRedo} />
                        </button>
                        <button 
                            className="btn btn-circle btn-sm btn-primary touch-manipulation"
                            onClick={handlePlayPause} 
                            disabled={!audioReady}
                        >
                            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                        </button>
                    </div>
                </div>
                <p className="text-sm opacity-70 line-clamp-2 mt-2">{title}</p>
            </div>
        </div>
    );
}

export default CompactWaveformPlayer;