import React, { useState } from 'react';
import '../components/AboutOurTeam.css'; // Make sure to create and link this CSS file for styling


const teamMembers = [
    {
        name: 'Cory Rose',
        title: 'CEO',
        role: 'Strategic Business & Marketing Leader',
        background: 'Music Industry Expert with 10+ years of professional experience. Worked alongside every major record label. 7x Multi-Platinum Music Producer & Audio Engineer, 1 billion+ streams. Worked alongside artists like Offset, Polo G, Don Toliver, Ryan Trey, Diddy, Bryson Tiller, and countless of others.',
        contributions: 'Industry connections, bridging music and AI technology. Leading the business and marketing strategy for YSM.AI.',
        image: '/Assets/Photos/founders/Cory.jpg'
    },
    {
        name: 'Andrew Rapier',
        title: 'CTO',
        role: 'Technical Visionary and AI Expert',
        background: 'Python & Technology Developer. Experienced in music production & audio engineering. Founder and developer of Beat It AT P&P LLC, a music production company servicing 113,000 registered members with an arsenal of 100+ music production products.',
        contributions: 'Technical vision, AI development, and music production expertise. Developing the AI technology that powers YSM.AI. Developing technical strategies to support fans and creators.',
        image: '/Assets/Photos/founders/Andrew.jpg'
    }
    // Add more team members here
];

// const PopUpMember =[
//     {
//         name: 'Cory Rose',
//         title: 'CEO',
//         role: 'Strategic Business & Marketing Leader',
//         background: 'Music Industry Expert with 10+ years of professional experience. Worked alongside every major record label. 7x Multi-Platinum Music Producer & Audio Engineer, 1 billion+ streams. Worked alongside artists like Offset, Polo G, Don Toliver, Ryan Trey, Diddy, Bryson Tiller, and countless of others.',
//     },
//     {
//         name: 'Andrew Rapier',
//         title: 'CTO',
//         role: 'Technical Visionary and AI Expert',
//         background: 'Python & Technology Developer. Experienced in music production & audio engineering. Founder and developer of Beat It AT P&P LLC, a music production company servicing 113,000 registered members with an arsenal of 100+ music production products.',
//     }
// ];

const MemberDetailsPopup = ({ member, onClose }) => {
    return (
        <div className="popup-background">
            <div className="popup-container">
                <h2>{member.name} - {member.title}</h2>
                <p><strong>Role:</strong> {member.role}</p>
                <p><strong>Background:</strong> {member.background}</p>
                <p><strong>Key Contributions:</strong> {member.contributions}</p>
                
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

const AboutOurTeam = () => {
    const [selectedMember, setSelectedMember] = useState(null);

    const handleMoreDetails = (member) => {
        setSelectedMember(member);
    };
    
    // Company Info
    const companyInfo = {
        mission: "YSM stands for 'Your Studio Maestro', symbolizing our commitment to preserving music creators' space in the AI revolution.",
        vision: "We are the first AI company to offer a music production platform powered by the music industry's top professionals, including Grammy Award winning artists, producers, and songwriters.",
        details: [
            {
                icon: "🎯",
                title: "Our Mission",
                description: "To democratize music production and make it accessible to everyone, while ensuring fair compensation for industry professionals."
            },
            {
                icon: "🌟",
                title: "Our Vision",
                description: "Connecting creators with the best artists, producers, and songwriters through AI-powered collaboration."
            },
            {
                icon: "💫",
                title: "Our Impact",
                description: "Building the future of music production by bridging traditional expertise with cutting-edge AI technology."
            }
        ],
        location: "Based in Los Angeles, California",
        foundingDate: "Founded in 2023",
        founders: "Cory Rose & Andrew Rapier"
    };

    return (
        <div className="min-h-screen flex flex-col bg-base-200">
            {/* Hero Section */}
            <div 
                className="hero min-h-[40vh] relative"
                style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url('/Assets/Photos/studio-2224493_1280.jpg')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-3xl">
                        <h1 className="text-6xl font-bold mb-8 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                            About YSM.AI
                        </h1>
                        <p className="text-2xl mb-4">Your Studio Maestro</p>
                    </div>
                </div>
            </div>

            {/* Company Info Section */}
            <div className="container mx-auto px-6 py-16 bg-base-100 rounded-xl shadow-xl">
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold mb-6">Our Story</h2>
                    <p className="text-lg mb-4 max-w-3xl mx-auto">{companyInfo.mission}</p>
                    <p className="text-lg max-w-3xl mx-auto">{companyInfo.vision}</p>
                    
                    {/* Company Quick Facts */}
                    <div className="flex flex-wrap justify-center gap-4 mt-8">
                        <div className="badge badge-primary badge-lg p-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            {companyInfo.location}
                        </div>
                        <div className="badge badge-secondary badge-lg p-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            {companyInfo.foundingDate}
                        </div>
                        <div className="badge badge-accent badge-lg p-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                            {companyInfo.founders}
                        </div>
                    </div>
                </div>

                {/* Company Values/Details Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
                    {companyInfo.details.map((detail, index) => (
                        <div key={index} className="card bg-base-200 shadow-xl hover:shadow-2xl transition-all">
                            <div className="card-body text-center">
                                <div className="text-4xl mb-4">{detail.icon}</div>
                                <h3 className="text-xl font-bold mb-4">{detail.title}</h3>
                                <p className="text-base-content/80">{detail.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Team Section */}
            <div className="container mx-auto px-6 py-12">
                <h2 className="text-3xl font-bold text-center mb-12">Meet Our Leadership Team</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {teamMembers.map(member => (
                        <div key={member.name} className="card bg-base-100 shadow-xl hover:shadow-2xl transition-all">
                            <figure className="px-6 pt-6 flex justify-center">
                                <div className="relative w-1/2 pt-[50%]">
                                    <img 
                                        src={member.image} 
                                        alt={member.name} 
                                        className="absolute top-0 left-0 w-full h-full rounded-xl object-cover object-center"
                                    />
                                </div>
                            </figure>
                            <div className="card-body">
                                <h3 className="card-title text-2xl mb-2">{member.name}</h3>
                                <div className="badge badge-primary mb-4">{member.title}</div>
                                <h4 className="font-semibold text-lg mb-2">{member.role}</h4>
                                <div className="space-y-4">
                                    <div>
                                        <p className="font-medium mb-2">Background</p>
                                        <p className="opacity-80">{member.background}</p>
                                    </div>
                                    <div>
                                        <p className="font-medium mb-2">Key Contributions</p>
                                        <p className="opacity-80">{member.contributions}</p>
                                    </div>
                                </div>
                                <div className="card-actions justify-end mt-4">
                                    <button 
                                        className="btn btn-primary"
                                        onClick={() => handleMoreDetails(member)}
                                    >
                                        More Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Popup remains the same but with updated styling */}
            {selectedMember && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-base-100 p-8 rounded-xl max-w-2xl w-full mx-4">
                        <h2 className="text-2xl font-bold mb-4">{selectedMember.name} - {selectedMember.title}</h2>
                        <div className="space-y-4">
                            <p><strong>Role:</strong> {selectedMember.role}</p>
                            <p><strong>Background:</strong> {selectedMember.background}</p>
                            <p><strong>Key Contributions:</strong> {selectedMember.contributions}</p>
                        </div>
                        <button 
                            className="btn btn-primary mt-6"
                            onClick={() => setSelectedMember(null)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AboutOurTeam;
