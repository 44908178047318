import React, { useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingPopup from '../components/popups/LoadingPopup';
import Notifications from '../components/Notifications';
import DrumUploadPopup from '../components/popups/drumSamplesUpload';
import { displayError, displaySuccess } from '../components/utils';
import { FaFileAudio, FaTimes, FaQuestionCircle, FaPlay, FaPause, FaInfoCircle } from 'react-icons/fa';
import { authFetch } from '../authFetch';
import { Drum } from 'lucide-react';

const DrumUpload = () => {
    const { getIdTokenClaims } = useAuth0();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const [errorMessages, setErrorMessages] = useState('');
    const [successMessages, setSuccessMessages] = useState('');
    //Playing File State
    const [playingFile, setPlayingFile] = useState(null);
    const audioRef = useRef(null);

    // Add new state for drum type
    const [drumType, setDrumType] = useState('');

    // Available drum types
    const drumTypes = [
        '808s',
        'Kicks',
        'Snares',
        'Hihats',
        'Open Hats',
        'Claps',
        'Cymbal',
        'Percussion',
        'FX',
        // Add more as needed
    ];

    // Show Key and Mode only for 808s
    const show808sFields = drumType === '808s';

    // Add state for multiple drum entries
    const [drumEntries, setDrumEntries] = useState([]);

    // Add state for hover highlighting
    const [hoveredSourceId, setHoveredSourceId] = useState(null);

    // Add state for drag-and-drop zone
    const [isDragging, setIsDragging] = useState(false);

    // Add state for page-wide drag and drop
    const [isPageDragging, setIsPageDragging] = useState(false);

    // Handle adding new row
    const addNewRow = () => {
        setDrumEntries([...drumEntries, {
            id: Date.now(),
            file: null,
            drumType: '',
            genres: [],
            artists: [],
            instruments: [],
            moods: [],
            tempoStyle: [],
            bpm: '',
            key: '',
            mode: ''
        }]);
    };

    // Handle removing row
    const removeRow = (id) => {
        setDrumEntries(drumEntries.filter(entry => entry.id !== id));
    };

    // Handle field updates
    const updateEntry = (id, field, value) => {
        setDrumEntries(drumEntries.map(entry => 
            entry.id === id ? { ...entry, [field]: value } : entry
        ));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const wavFiles = files.filter(file => file.type === 'audio/wav');

        if (wavFiles.length !== files.length) {
            setErrorMessage('Only WAV files are allowed.');
        } else {
            setErrorMessage('');
            setSelectedFiles(prevFiles => [...prevFiles, ...wavFiles.map(file => ({
                file,
                url: URL.createObjectURL(file)
            }))]);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        const wavFiles = files.filter(file => file.type === 'audio/wav');
    
        if (wavFiles.length !== files.length) {
          setErrorMessage('Only WAV files are allowed.');
        } else {
          setErrorMessage('');
          setSelectedFiles(prevFiles => [...prevFiles, ...wavFiles.map(file => ({
            file,
            url: URL.createObjectURL(file)
          }))]);
        }
      };

      const handleRemoveFile = (index) => {
        const newFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(newFiles);
        if (playingFile === index) {
          setPlayingFile(null);
          if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
          }
        }
      };
    
      const handlePlayPause = (entryId) => {
        if (playingFile === entryId) {
            audioRef.current.pause();
            setPlayingFile(null);
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
            }
            const entry = drumEntries.find(e => e.id === entryId);
            if (entry && entry.file) {
                audioRef.current = new Audio(URL.createObjectURL(entry.file));
                audioRef.current.play();
                setPlayingFile(entryId);
                
                // Auto-stop when audio ends
                audioRef.current.onended = () => {
                    setPlayingFile(null);
                };
            }
        }
    };
    
      const handleTagChange = (e, setter, tagArray) => {
        if (e.key === 'Enter' || e.key === ',') {
          const value = e.target.value.replace(/,$/, '').trim();
          if (value && !tagArray.includes(value)) {
            setter([...tagArray, value]);
          }
          e.target.value = '';
          e.preventDefault();
        }
      };

      const handleRemoveTag = (entryId, field, index) => {
        setDrumEntries(drumEntries.map(entry => {
            if (entry.id === entryId) {
                return {
                    ...entry,
                    [field]: entry[field].filter((_, i) => i !== index)
                };
            }
            return entry;
        }));
      };
    
      const handleHowToUploadClick = () => {
        setIsPopupOpen(true); // Open the popup
      };
    
      const handleClosePopup = () => {
        setIsPopupOpen(false); // Close the popup
      };


      const handleSubmit = async () => {
        setIsLoading(true);
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims.__raw;
    
        try {
            // Group entries by drum type for sequential ordering
            const entriesByType = drumEntries.reduce((acc, entry) => {
                if (!entry.drumType) return acc;
                if (!acc[entry.drumType]) acc[entry.drumType] = [];
                acc[entry.drumType].push(entry);
                return acc;
            }, {});

            // Convert files to base64 for each entry, maintaining order within drum types
            const entriesData = await Promise.all(
                Object.entries(entriesByType).flatMap(([drumType, entries], typeIndex) => 
                    entries.map(async (entry, entryIndex) => {
                        // Convert file to base64
                        const fileData = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = error => reject(error);
                            reader.readAsDataURL(entry.file);
                        });

                        // Return formatted entry data with sequence information
                        return {
                            file: {
                                name: entry.file.name,
                                data: fileData
                            },
                            drumType: entry.drumType,
                            genres: entry.genres,
                            artists: entry.artists,
                            instruments: entry.instruments,
                            moods: entry.moods,
                            tempoStyle: entry.tempoStyle,
                            bpm: entry.bpm,
                            ...(entry.drumType === '808s' && {
                                key: entry.key,
                                mode: entry.mode
                            }),
                            // Add sequence information
                            sequence: {
                                typeIndex,      // Order of this drum type
                                entryIndex,     // Order within this drum type
                                totalInType: entries.length  // Total entries of this type
                            }
                        };
                    })
                )
            );

            // Log the payload for debugging
            console.log('Submission Payload:', {
                entries: entriesData.map(entry => ({
                    ...entry,
                    file: {
                        name: entry.file.name,
                        data: entry.file.data.substring(0, 50) + '...' // Truncate base64 for logging
                    }
                }))
            });

            const response = await authFetch(
                `${process.env.REACT_APP_BACKEND_URL}/uploadingdrumsamples`,
                {
                    method: 'POST',
                    body: JSON.stringify({ 
                        entries: entriesData,
                        metadata: {
                            totalTypes: Object.keys(entriesByType).length,
                            typeBreakdown: Object.fromEntries(
                                Object.entries(entriesByType).map(([type, entries]) => [type, entries.length])
                            )
                        }
                    }),
                },
                getAccessTokenSilently
            );

            console.log('Response from server:', response);
            displaySuccess(setSuccessMessages)('Drum samples uploaded successfully!');
            
            // Reset form after successful upload
            setDrumEntries([{
                id: Date.now(),
                file: null,
                drumType: '',
                genres: [],
                artists: [],
                instruments: [],
                moods: [],
                tempoStyle: [],
                bpm: '',
                key: '',
                mode: ''
            }]);

        } catch (error) {
            console.error('Upload Error:', error);
            if (error.status === 403 || error.status === 401) {
                displayError(setErrorMessages)('Unauthorized access. Please log in again.');
            } else {
                displayError(setErrorMessages)('Failed to upload drum samples. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Add this helper function to check if an entry is valid
    const isEntryValid = (entry) => {
        const baseRequirements = 
            entry.file && // Has file
            entry.drumType && // Has drum type
            entry.genres.length > 0 && // Has at least one genre
            entry.artists.length > 0 && // Has at least one artist
            entry.moods.length > 0 && // Has at least one mood
            entry.tempoStyle.length > 0 && // Has at least one tempo style
            entry.bpm; // Has BPM

        // Additional requirements for 808s
        if (entry.drumType === '808s') {
            return baseRequirements && entry.key && entry.mode;
        }

        return baseRequirements;
    };

    // Add new function to copy metadata
    const copyMetadataFrom = (sourceId, targetId, field) => {
        setDrumEntries(drumEntries.map(entry => {
            if (entry.id === targetId) {
                const sourceEntry = drumEntries.find(e => e.id === sourceId);
                if (sourceEntry) {
                    if (field === 'all') {
                        return {
                            ...entry,
                            // Exclude drumType and bpm when copying all
                            genres: [...sourceEntry.genres],
                            artists: [...sourceEntry.artists],
                            moods: [...sourceEntry.moods],
                            tempoStyle: [...sourceEntry.tempoStyle],
                            ...(entry.drumType === '808s' && sourceEntry.drumType === '808s' && {
                                key: sourceEntry.key,
                                mode: sourceEntry.mode
                            })
                        };
                    }
                    return {
                        ...entry,
                        [field]: [...sourceEntry[field]]
                    };
                }
            }
            return entry;
        }));
    };

    // Add function to copy metadata to all rows
    const copyToAllRows = (sourceId, field) => {
        const sourceEntry = drumEntries.find(e => e.id === sourceId);
        if (!sourceEntry) return;

        setDrumEntries(drumEntries.map(entry => {
            if (entry.id === sourceId) return entry;
            
            if (field === 'all') {
                return {
                    ...entry,
                    // Exclude drumType and bpm when copying all
                    genres: [...sourceEntry.genres],
                    artists: [...sourceEntry.artists],
                    moods: [...sourceEntry.moods],
                    tempoStyle: [...sourceEntry.tempoStyle],
                    ...(entry.drumType === '808s' && sourceEntry.drumType === '808s' && {
                        key: sourceEntry.key,
                        mode: sourceEntry.mode
                    })
                };
            }
            return {
                ...entry,
                [field]: [...sourceEntry[field]]
            };
        }));
    };

    // Handle drag events
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    // Handle page-wide drag events
    const handlePageDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPageDragging(true);
    };

    const handlePageDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX;
        const y = e.clientY;

        // Only set to false if we've actually left the page boundaries
        if (x <= rect.left || x >= rect.right || y <= rect.top || y >= rect.bottom) {
            setIsPageDragging(false);
        }
    };

    const handlePageDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // Helper function to normalize text for comparison
    const normalizeText = (text) => {
        return text
            .toLowerCase()
            // Replace common separators with spaces
            .replace(/[-_.,]/g, ' ')
            // Remove special characters
            .replace(/[^\w\s]/g, '')
            // Remove extra spaces
            .replace(/\s+/g, ' ')
            .trim();
    };

    // Enhanced helper function to detect drum type from filename
    const detectDrumType = (filename) => {
        const normalizedName = normalizeText(filename);
        
        // Create a map of normalized drum types and their original values
        const drumTypeMap = drumTypes.reduce((acc, type) => {
            // Add the full type
            acc[normalizeText(type)] = type;
            
            // Add common variations
            const variations = {
                'hihat': 'Hi-Hat',
                'hat': 'Hi-Hat',
                'hh': 'Hi-Hat',
                'oh': 'Open Hat',
                'ophat': 'Open Hat',
                'openhat': 'Open Hat',
                'kick': 'Kick',
                'bd': 'Kick', // bass drum
                'snr': 'Snare',
                'sd': 'Snare',
                'clp': 'Clap',
                'perc': 'Percussion',
                'percussion': 'Percussion',
                'cym': 'Cymbal',
                'cymbal': 'Cymbal',
                'crash': 'Cymbal',
                'ride': 'Cymbal',
                'fx': 'FX',
                'effect': 'FX',
                'effects': 'FX',
                '808': '808s'
            };

            // Add variations for this type
            Object.entries(variations).forEach(([variant, originalType]) => {
                if (originalType === type) {
                    acc[variant] = type;
                }
            });

            return acc;
        }, {});

        // Split the filename into parts and check each part
        const parts = normalizedName.split(' ');
        
        // First try to match multiple consecutive words (for types like "Open Hat")
        for (let i = 0; i < parts.length - 1; i++) {
            const twoWordMatch = `${parts[i]} ${parts[i + 1]}`;
            if (drumTypeMap[twoWordMatch]) {
                return drumTypeMap[twoWordMatch];
            }
        }

        // Then try to match single words
        for (const part of parts) {
            if (drumTypeMap[part]) {
                return drumTypeMap[part];
            }
        }

        // Try partial matches if no exact match found
        for (const part of parts) {
            for (const [key, value] of Object.entries(drumTypeMap)) {
                if (part.includes(key) || key.includes(part)) {
                    return value;
                }
            }
        }

        return '';
    };

    // Enhanced helper function to detect BPM from filename
    const detectBPM = (filename) => {
        const normalizedName = normalizeText(filename);
        
        // Try different BPM patterns
        const patterns = [
            // 153bpm, 153 bpm, 153-bpm
            /\b(\d{2,3})(?:\s*-?\s*)?(?:bpm)\b/i,
            // bpm153, bpm 153, bpm-153
            /\bbpm(?:\s*-?\s*)?(\d{2,3})\b/i,
            // Just a number between 70-200 (common BPM range)
            /\b([7-9][0-9]|1[0-9][0-9]|200)\b/
        ];

        for (const pattern of patterns) {
            const match = normalizedName.match(pattern);
            if (match) {
                const bpm = parseInt(match[1]);
                // Validate BPM is in reasonable range
                if (bpm >= 70 && bpm <= 200) {
                    return match[1];
                }
            }
        }

        return '';
    };

    // Add function to detect key from filename
    const detectKey = (filename) => {
        const normalizedName = normalizeText(filename);
        
        // Match patterns like "C#", "Am", "Bbm", "F#min", etc.
        const keyPattern = /\b([A-G][#b]?(?:\s*(?:min|maj|m|major|minor)?))(?:\s|$)/i;
        const match = normalizedName.match(keyPattern);
        
        if (match) {
            const key = match[1].replace(/(?:min|maj|major|minor|m)$/i, '').toUpperCase();
            return key;
        }
        
        return '';
    };

    // Add function to detect mode from filename
    const detectMode = (filename) => {
        const normalizedName = normalizeText(filename);
        
        if (/\b(?:min|minor|m)\b/i.test(normalizedName)) {
            return 'Minor';
        }
        if (/\b(?:maj|major)\b/i.test(normalizedName)) {
            return 'Major';
        }
        
        return '';
    };

    // Modify the handleBulkDrop function to use the new detection functions
    const handleBulkDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        setIsPageDragging(false);

        const files = Array.from(e.dataTransfer.files);
        const wavFiles = files.filter(file => file.type === 'audio/wav');

        if (wavFiles.length !== files.length) {
            setErrorMessage('Only WAV files are allowed.');
            return;
        }

        // Create new entries for each file
        const newEntries = wavFiles.map(file => {
            const drumType = detectDrumType(file.name);
            const is808 = drumType === '808s';
            
            return {
                id: Date.now() + Math.random(),
                file: file,
                drumType: drumType,
                genres: [],
                artists: [],
                instruments: [],
                moods: [],
                tempoStyle: [],
                bpm: detectBPM(file.name) || '',
                key: is808 ? detectKey(file.name) : '',
                mode: is808 ? detectMode(file.name) : ''
            };
        });

        setDrumEntries(prevEntries => [...prevEntries, ...newEntries]);
        setErrorMessage('');
    };

    // Add column info content
    const columnInfo = {
        type: {
            title: "Drum Type",
            description: "Select the category of your drum sample.",
            examples: "Examples: Kicks, Snares, Hi-hats, 808s, Percussion, etc."
        },
        genres: {
            title: "Genres",
            description: "List the musical genres this drum sample is suitable for.",
            examples: "Examples: Hip Hop, Trap, R&B, Pop, Rock, etc."
        },
        artists: {
            title: "Similar Artists",
            description: "Reference artists whose style matches this drum sample.",
            examples: "Examples: Drake, Metro Boomin, Travis Scott, etc."
        },
        moods: {
            title: "Moods",
            description: "Describe the emotional qualities or atmosphere of the sound.",
            examples: "Examples: Dark, Energetic, Mellow, Aggressive, etc."
        },
        tempoStyle: {
            title: "Tempo Style",
            description: "Indicate the tempo range or style this sample works best with.",
            examples: "Examples: Slow, Medium, Fast, Half-time, Double-time, etc."
        },
        bpm: {
            title: "BPM",
            description: "The tempo of the drum sample in beats per minute.",
            examples: "Examples: 140, 160, 80, etc."
        },
        keyMode: {
            title: "Key/Mode",
            description: "For 808s only: Specify the musical key and mode.",
            examples: "Examples: C Major, F# Minor, etc."
        }
    };

    // Add InfoTooltip component
    const InfoTooltip = ({ info }) => {
        const [isOpen, setIsOpen] = useState(false);

        return (
            <div className="dropdown dropdown-hover dropdown-end inline-block ml-1">
                <label tabIndex={0} className="text-base-content/50 hover:text-primary cursor-help">
                    <FaInfoCircle className="inline-block w-4 h-4" />
                </label>
                <div tabIndex={0} className="dropdown-content z-[1] card card-compact w-64 p-2 shadow bg-base-100 border border-base-300">
                    <div className="card-body p-3">
                        <h3 className="font-bold text-sm">{info.title}</h3>
                        <p className="text-xs text-base-content/70">{info.description}</p>
                        <p className="text-xs text-base-content/70 mt-1">{info.examples}</p>
                    </div>
                </div>
            </div>
        );
    };

return (
    <div 
        className="min-h-screen p-6 bg-base-200 relative"
        onDragEnter={handlePageDragEnter}
        onDragLeave={handlePageDragLeave}
        onDragOver={handlePageDragOver}
        onDrop={handleBulkDrop}
    >
        {/* Page-wide drag overlay */}
        {isPageDragging && (
            <div className="fixed inset-0 bg-primary/10 pointer-events-none z-50 border-2 border-primary border-dashed">
                <div className="flex items-center justify-center h-full">
                    <div className="bg-base-100 rounded-lg p-8 shadow-lg text-center">
                        <FaFileAudio className="w-16 h-16 text-primary mx-auto mb-4" />
                        <h3 className="text-xl font-bold mb-2">Drop Files Anywhere</h3>
                        <p className="text-base-content/70">Release to add more drum samples</p>
                    </div>
                </div>
            </div>
        )}

        <div className="container mx-auto max-w-7xl">
            {/* Header Section */}
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h1 className="text-3xl font-bold">Drum Sample Upload</h1>
                    <p className="text-base-content/70 mt-1">Upload multiple drum samples with detailed metadata</p>
                </div>
                <button
                    className="btn btn-primary btn-sm gap-2"
                    onClick={handleHowToUploadClick}
                >
                    <FaQuestionCircle /> Upload Guide
                </button>
            </div>

            {/* Collapsible Metadata Instructions */}
            <div className="collapse collapse-arrow bg-base-100 shadow-xl mb-6">
                <input type="checkbox" className="peer" /> 
                <div className="collapse-title text-lg font-medium flex items-center gap-2">
                    <FaInfoCircle className="text-primary" />
                    How to Add Metadata
                    <span className="text-xs text-base-content/50 ml-2">(Click to expand)</span>
                </div>
                <div className="collapse-content">
                    <div className="space-y-4 pt-2">
                        <div className="alert alert-info bg-info/10">
                            <div>
                                <p className="font-semibold">Important:</p>
                                <p>All metadata fields are required for a successful upload. This helps others find and use your samples effectively.</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <h4 className="font-semibold mb-2">Adding Tags:</h4>
                                <ul className="list-disc list-inside space-y-1 text-sm">
                                    <li>Type your tag in the input field</li>
                                    <li>Press <span className="badge badge-sm">Enter</span> or type a <span className="badge badge-sm">,</span> (comma) to add it</li>
                                    <li>Each tag will appear as a badge below the input</li>
                                    <li>Click the × on a badge to remove it</li>
                                </ul>
                            </div>
                            <div>
                                <h4 className="font-semibold mb-2">Required Fields:</h4>
                                <ul className="list-disc list-inside space-y-1 text-sm">
                                    <li>At least one file must be uploaded</li>
                                    <li>Drum Type must be selected</li>
                                    <li>All tag fields need at least one entry</li>
                                    <li>BPM must be a number</li>
                                    <li>For 808s: Key and Mode must be selected</li>
                                </ul>
                            </div>
                        </div>
                        <div className="text-sm text-base-content/70">
                            <p>💡 Tip: Hover over the (i) icon next to each field for examples and descriptions of what to enter.</p>
                        </div>
                        <div className="text-sm text-base-content/70">
                            <p>💡 Pro Tip: Name your files with the drum type and BPM for automatic detection (e.g., "Trap Kick 140 BPM.wav")</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Upload Card */}
            <div className="card bg-base-100 shadow-xl">
                <div className="card-body p-4">
                    {/* Header Row - Only show when there are entries */}
                    {drumEntries.length > 0 && (
                        <div className="grid grid-cols-[1.5fr,1fr,1fr,1fr,1fr,1fr,0.8fr,1fr,0.6fr] gap-2 mb-2 text-sm font-semibold text-base-content/80 border-b border-base-300 pb-2">
                            <div>File</div>
                            <div className="flex items-center">
                                Type
                                <InfoTooltip info={columnInfo.type} />
                            </div>
                            <div className="flex items-center">
                                Genres
                                <InfoTooltip info={columnInfo.genres} />
                            </div>
                            <div className="flex items-center">
                                Artists
                                <InfoTooltip info={columnInfo.artists} />
                            </div>
                            <div className="flex items-center">
                                Style
                                <InfoTooltip info={columnInfo.moods} />
                            </div>
                            <div className="flex items-center">
                                Tempo Style
                                <InfoTooltip info={columnInfo.tempoStyle} />
                            </div>
                            <div className="flex items-center">
                                BPM
                                <InfoTooltip info={columnInfo.bpm} />
                            </div>
                            <div className="flex items-center">
                                Key/Mode
                                <InfoTooltip info={columnInfo.keyMode} />
                            </div>
                            <div className="text-center">Actions</div>
                        </div>
                    )}

                    {/* Show drag-and-drop area when no entries */}
                    {drumEntries.length === 0 ? (
                        <div 
                            className={`border-2 border-dashed rounded-lg p-12 text-center transition-colors ${
                                isDragging 
                                    ? 'border-primary bg-primary/10' 
                                    : 'border-base-content/20 hover:border-primary'
                            }`}
                            onDragEnter={handleDragEnter}
                            onDragLeave={handleDragLeave}
                            onDragOver={handleDragOver}
                            onDrop={handleBulkDrop}
                        >
                            <div className="flex flex-col items-center gap-6">
                                <Drum className="w-16 h-16 text-primary/70" />
                                <div>
                                    <p className="text-xl font-medium mb-2">Drag and drop drum loop stem files here</p>
                                    <p className="text-base text-base-content/70 mb-4">or add a row and add each one manually</p>
                                    <button
                                        onClick={addNewRow}
                                        className="btn btn-primary btn-sm gap-2"
                                    >
                                        + Add Row
                                    </button>
                                    <p className="text-xs text-base-content/50 mt-4">
                                        Tip: Name your files with the drum type and BPM for automatic detection<br />
                                        Example: "Trap Kick 140 BPM.wav" or "Hi-Hat 128bpm.wav"
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        /* Drum Entries */
                        <div className="space-y-2">
                            {drumEntries.map((entry, index) => (
                                <div 
                                    key={entry.id} 
                                    className={`grid grid-cols-[1.5fr,1fr,1fr,1fr,1fr,1fr,0.8fr,1fr,0.6fr] gap-2 items-start p-2 rounded-lg transition-colors ${
                                        hoveredSourceId === entry.id 
                                            ? 'bg-primary/10' 
                                            : 'bg-base-200/50'
                                    }`}
                                >
                                    {/* File Upload Cell */}
                                    <div className="relative">
                                        <input
                                            type="file"
                                            accept=".wav"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) updateEntry(entry.id, 'file', file);
                                            }}
                                            className="hidden"
                                            id={`file-${entry.id}`}
                                        />
                                        <label
                                            htmlFor={`file-${entry.id}`}
                                            className={`flex flex-col items-center justify-center p-2 border-2 border-dashed rounded-lg cursor-pointer transition-colors ${
                                                entry.file ? 'border-success/50 hover:border-success' : 'border-error/50 hover:border-error'
                                            }`}
                                        >
                                            {entry.file ? (
                                                <div className="w-full">
                                                    <div className="text-xs truncate mb-1">{entry.file.name}</div>
                                                    <button
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handlePlayPause(entry.id);
                                                        }}
                                                        className="btn btn-xs btn-primary w-full gap-1"
                                                    >
                                                        {playingFile === entry.id ? <FaPause /> : <FaPlay />}
                                                        {playingFile === entry.id ? 'Pause' : 'Play'}
                                                    </button>
                                                </div>
                                            ) : (
                                                <>
                                                    <FaFileAudio className="text-lg mb-1" />
                                                    <span className="text-xs">Drop WAV</span>
                                                </>
                                            )}
                                        </label>
                                    </div>

                                    {/* Type Selector */}
                                    <select
                                        value={entry.drumType}
                                        onChange={(e) => updateEntry(entry.id, 'drumType', e.target.value)}
                                        className="select select-bordered select-sm w-full"
                                    >
                                        <option value="">Type</option>
                                        {drumTypes.map(type => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </select>

                                    {/* Tags Input Fields */}
                                    {['genres', 'artists', 'moods', 'tempoStyle'].map((field) => (
                                        <div key={field} className="flex flex-col gap-1">
                                            <input
                                                type="text"
                                                placeholder={`Add ${field}...`}
                                                className="input input-bordered input-sm w-full"
                                                onKeyUp={(e) => handleTagChange(e, (value) => updateEntry(entry.id, field, value), entry[field])}
                                            />
                                            <div className="flex flex-wrap gap-1">
                                                {entry[field].map((tag, idx) => (
                                                    <span key={idx} className="badge badge-sm badge-primary">
                                                        {tag}
                                                        <button 
                                                            onClick={() => handleRemoveTag(entry.id, field, idx)}
                                                            className="ml-1"
                                                        >×</button>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    ))}

                                    {/* BPM Input */}
                                    <input
                                        type="text"
                                        placeholder="BPM"
                                        value={entry.bpm}
                                        onChange={(e) => updateEntry(entry.id, 'bpm', e.target.value)}
                                        className="input input-bordered input-sm w-full"
                                    />

                                    {/* Key and Mode Inputs with more space */}
                                    <div className="flex flex-col gap-2">
                                        {entry.drumType === '808s' ? (
                                            <>
                                                <select
                                                    value={entry.key}
                                                    onChange={(e) => updateEntry(entry.id, 'key', e.target.value)}
                                                    className="select select-bordered select-sm w-full"
                                                >
                                                    <option value="">Key</option>
                                                    {['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'].map(note => (
                                                        <option key={note} value={note}>{note}</option>
                                                    ))}
                                                </select>
                                                <select
                                                    value={entry.mode}
                                                    onChange={(e) => updateEntry(entry.id, 'mode', e.target.value)}
                                                    className="select select-bordered select-sm w-full"
                                                >
                                                    <option value="">Mode</option>
                                                    <option value="Major">Major</option>
                                                    <option value="Minor">Minor</option>
                                                </select>
                                            </>
                                        ) : (
                                            <div className="text-xs text-base-content/50 h-full flex items-center justify-center">
                                                N/A
                                            </div>
                                        )}
                                    </div>

                                    {/* Actions */}
                                    <div className="flex justify-center gap-2">
                                        <div className="dropdown dropdown-end">
                                            <label tabIndex={0} className="btn btn-ghost btn-xs">
                                                Copy From
                                            </label>
                                            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                                                {drumEntries.map((sourceEntry) => (
                                                    sourceEntry.id !== entry.id && (
                                                        <li 
                                                            key={sourceEntry.id} 
                                                            className="text-sm"
                                                            onMouseEnter={() => setHoveredSourceId(sourceEntry.id)}
                                                            onMouseLeave={() => setHoveredSourceId(null)}
                                                        >
                                                            <div className="font-semibold">{sourceEntry.file?.name || 'Unnamed Entry'}</div>
                                                            <ul className="pl-4">
                                                                <li>
                                                                    <button onClick={() => copyMetadataFrom(sourceEntry.id, entry.id, 'all')}>
                                                                        Copy All Metadata
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => copyMetadataFrom(sourceEntry.id, entry.id, 'genres')}>
                                                                        Copy Genres
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => copyMetadataFrom(sourceEntry.id, entry.id, 'artists')}>
                                                                        Copy Artists
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => copyMetadataFrom(sourceEntry.id, entry.id, 'moods')}>
                                                                        Copy Moods
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => copyMetadataFrom(sourceEntry.id, entry.id, 'tempoStyle')}>
                                                                        Copy Tempo Style
                                                                    </button>
                                                                </li>
                                                                <div className="divider my-1"></div>
                                                                <li>
                                                                    <button onClick={() => copyToAllRows(sourceEntry.id, 'all')}>
                                                                        Copy All Metadata to All Rows
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => copyToAllRows(sourceEntry.id, 'genres')}>
                                                                        Copy Genres to All Rows
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => copyToAllRows(sourceEntry.id, 'artists')}>
                                                                        Copy Artists to All Rows
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => copyToAllRows(sourceEntry.id, 'moods')}>
                                                                        Copy Moods to All Rows
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => copyToAllRows(sourceEntry.id, 'tempoStyle')}>
                                                                        Copy Tempo Style to All Rows
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    )
                                                ))}
                                            </ul>
                                        </div>
                                        <button
                                            onClick={() => removeRow(entry.id)}
                                            className="btn btn-ghost btn-sm btn-square text-error"
                                        >
                                            <FaTimes />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Add Row Button */}
                    <div className="mt-4">
                        <button
                            onClick={addNewRow}
                            className="btn btn-outline btn-sm gap-2"
                        >
                            + Add Row
                        </button>
                    </div>

                    {/* Submit Button */}
                    <div className="mt-6">
                        <button
                            onClick={handleSubmit}
                            disabled={!drumEntries.some(isEntryValid)}
                            className="btn btn-primary w-full"
                        >
                            Upload Drum Samples
                        </button>
                    </div>
                </div>
            </div>
        </div>

        {/* Popups */}
        <LoadingPopup isOpen={isLoading} />
        <DrumUploadPopup isOpen={isPopupOpen} onClose={handleClosePopup} />
    </div>
);
};

export default DrumUpload;