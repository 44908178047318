import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BannerPage from '../signups/Banner'; // Assuming your Banner component is named BannerPage
import { useNavigate } from 'react-router-dom';
import CompactWaveformPlayer from '../components/CompactWaveformPlayer';
import LandingFooter from '../components/LandingFooter';

const audioTracks = [
  {
    title: "80s rock synthwave type beat",
    genre: "80s Synthwave",
    filename: "80s rock synthwave type beat.flac",
    path: "https://ysm-landingpage.s3.us-west-1.amazonaws.com/mp3/80s+rock+synthwave+type+beat.mp3"
  },
  {
    title: "ariana grande type beat with snappy fast hihats and snare rolls",
    genre: "Pop",
    filename: "ariana grande type beat with snappy fast hihats and snare rolls.flac",
    path: "https://ysm-landingpage.s3.us-west-1.amazonaws.com/mp3/ariana+grande+type+beat+with+snappy+fast+hihats+and+snare+rolls.mp3"
  },
  {
    title: "bryson tiller rnb type beat with trap drums and cymbal crash and riser",
    genre: "R&B Trap",
    filename: "bryson tiller rnb type beat with trap drums and cymbal crash and riser.flac",
    path: "https://ysm-landingpage.s3.us-west-1.amazonaws.com/mp3/bryson+tiller+rnb+type+beat+with+trap+drums+and+cymbal+crash+and+riser.mp3"
  },
  {
    title: "lil durk type beat with a riser sweep fx snare roll fast snares trap snares",
    genre: "Rap - Trap",
    filename: "lil durk type beat with a riser sweep fx snare roll fast snares trap snares.flac",
    path: "https://ysm-landingpage.s3.us-west-1.amazonaws.com/mp3/lil+durk+type+beat+with+a+riser+sweep+fx+snare+roll+fast+snares+trap+snares.mp3"
  }
];

const LandingPage = () => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    loginWithRedirect();
  };

  const handleDatasetClick = () => {
    navigate('/data-set');
  };

  // Add this function to handle audio loading errors
  const handleAudioError = (e) => {
    console.error("Audio loading error:", e);
  };

  return (
    <div className="min-h-screen flex flex-col bg-base-200">
      {/* Hero Section with Background Image - Darker overlay */}
      <div 
        className="hero min-h-[80vh] relative"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url('/Assets/Photos/studio-2224493_1280.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="hero-content text-center text-neutral-content">
          <div className="max-w-3xl">
            <h1 className="text-6xl font-bold mb-8 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              Your Studio Maestro
            </h1>
            <p className="text-2xl mb-8">
              The Future of Music Production is Here
            </p>
            <p className="text-lg mb-8">
              Join 500+ artists already using YSM to create, collaborate, and earn from AI-powered music production
            </p>
            <div className="flex gap-4 justify-center mb-4">
              <button
                className="btn btn-primary btn-lg"
                onClick={handleLoginClick}
              >
                Start Creating Now
              </button>
              <button
                className="btn btn-ghost btn-lg text-white hover:btn-primary"
                onClick={() => navigate('/subscription-features')}
              >
                View Pricing
              </button>
            </div>
            
            {/* New Credibility Section - Added more bottom padding for mobile */}
            <div className="mt-8 mb-16 sm:mb-4 space-y-4">
              <div className="flex items-center justify-center gap-2 text-lg font-medium">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Founded by Billboard #1 Charting Producers</span>
              </div>
              <div className="flex flex-wrap justify-center items-center gap-6">
                <div className="badge badge-primary badge-lg gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                  </svg>
                  Industry Verified
                </div>
                <div className="badge badge-secondary badge-lg gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
                  Secure Platform
                </div>
                <div className="badge badge-accent badge-lg gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                  Fast Generation
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section - Adjusted margin top for mobile */}
      <div className="stats stats-vertical sm:stats-horizontal shadow-lg mx-4 lg:mx-auto sm:-mt-16 mt-4 bg-base-100 z-10">
        <div className="stat">
          <div className="stat-title">Early Access Offer</div>
          <div className="stat-value text-primary">$7.50</div>
          <div className="stat-desc">Limited time - 62.5% off!</div>
        </div>
        
        <div className="stat">
          <div className="stat-title">Producer Credits</div>
          <div className="stat-value">50M+</div>
          <div className="stat-desc">Combined record sales</div>
        </div>
        
        <div className="stat">
          <div className="stat-title">AI Generations</div>
          <div className="stat-value">10k+</div>
          <div className="stat-desc">Beats created monthly</div>
        </div>
      </div>

      {/* Sample Generations Section */}
      <div className="container mx-auto my-16 px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Listen to AI-Generated Beats</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {audioTracks.map((track, index) => (
            <CompactWaveformPlayer
              key={index}
              audio_url={track.path}
              genre={track.genre}
              title={track.title}
            />
          ))}
        </div>
      </div>

      {/* Features Grid - Enhanced with better hover animations */}
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-6 mb-12">
        <div className="card bg-base-100 shadow-xl transform transition-all duration-300 hover:-translate-y-2 hover:bg-base-200 border border-transparent hover:border-primary">
          <div className="card-body">
            <h2 className="card-title">
              <span className="text-success">✓</span> Beat Generation
            </h2>
            <p>Create unique beats in seconds using our AI model. Full rights to your generated tracks.</p>
            <ul className="list-disc list-inside text-sm mt-2 space-y-1">
              <li>Multiple genre support</li>
              <li>Customizable parameters</li>
              <li>High-quality output</li>
            </ul>
            <div className="card-actions justify-end">
              <div className="badge badge-success">Available Now</div>
            </div>
          </div>
        </div>

        <div className="card bg-base-100 shadow-xl transform transition-all duration-300 hover:-translate-y-2 hover:bg-base-200 border border-transparent hover:border-primary">
          <div className="card-body">
            <h2 className="card-title">
              <span className="text-warning">⌛</span> Lyric Generation
            </h2>
            <p>Collaborate with AI trained on authentic songwriter data to create meaningful, original lyrics for your tracks.</p>
            <div className="card-actions justify-end">
              <div className="badge badge-warning">Coming Soon</div>
            </div>
          </div>
        </div>

        <div className="card bg-base-100 shadow-xl transform transition-all duration-300 hover:-translate-y-2 hover:bg-base-200 border border-transparent hover:border-primary">
          <div className="card-body">
            <h2 className="card-title">
              <span className="text-warning">⌛</span> Voice Generation
            </h2>
            <p>Create vocal tracks with AI models trained by participating artists, with fair compensation for all contributors.</p>
            <div className="card-actions justify-end">
              <div className="badge badge-warning">Coming Soon</div>
            </div>
          </div>
        </div>
      </div>

      {/* Artist Contribution Section */}
      <div className="bg-gradient-to-r from-base-300 to-base-100 py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-8">
            For Artists & Producers
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            {/* Left side - Benefits */}
            <div className="space-y-6">
              <h3 className="text-2xl font-semibold mb-4 text-primary">
                Monetize Your Sound Library
              </h3>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <div className="text-2xl">⚖️</div>
                  <div>
                    <h4 className="font-semibold">Retain Your Rights</h4>
                    <p className="text-sm opacity-80">Keep 100% ownership of your masters and publishing while earning from AI-generated content</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="text-2xl">💎</div>
                  <div>
                    <h4 className="font-semibold">Passive Income</h4>
                    <p className="text-sm opacity-80">Earn royalties every time your sounds are used in AI generations or licensed</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="text-2xl">🏆</div>
                  <div>
                    <h4 className="font-semibold">Fair Compensation</h4>
                    <p className="text-sm opacity-80">Get paid for every sale, license, and usage of your contributed content</p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <div className="text-2xl">🌐</div>
                  <div>
                    <h4 className="font-semibold">Expand Your Reach</h4>
                    <p className="text-sm opacity-80">Let AI help distribute your sound across genres and styles</p>
                  </div>
                </div>
              </div>
              <div className="pt-4">
                <button 
                  className="btn btn-primary"
                  onClick={() => navigate('/choice')}
                >
                  Join Creator Program
                </button>
                <span className="ml-4 text-sm opacity-70">Early contributors receive priority in generation selections</span>
              </div>
            </div>

            {/* Right side - Stats/Info */}
            <div className="bg-base-100 p-8 rounded-xl shadow-xl">
              <h3 className="text-xl font-semibold mb-6">Creator Benefits</h3>
              <div className="stats stats-vertical shadow w-full">
                <div className="stat">
                  <div className="stat-title">Revenue Share</div>
                  <div className="stat-value text-primary">90%*</div>
                  <div className="stat-desc">Of licensing revenue</div>
                </div>
                <div className="stat">
                  <div className="stat-title">Ownership</div>
                  <div className="stat-value">100%</div>
                  <div className="stat-desc">Keep all your rights</div>
                </div>
                <div className="stat">
                  <div className="stat-title">Payment Speed</div>
                  <div className="stat-value text-secondary">Instant</div>
                  <div className="stat-desc">Via Stripe Connect</div>
                </div>
              </div>
              <div className="mt-6 text-sm">
                <div className="flex items-center gap-2 mb-2">
                  <svg className="h-5 w-5 text-success" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Transparent revenue tracking</span>
                </div>
                <div className="flex items-center gap-2 mb-2">
                  <svg className="h-5 w-5 text-success" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Monthly performance reports</span>
                </div>
                <div className="flex items-center gap-2">
                  <svg className="h-5 w-5 text-success" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Direct deposit payments</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action - Enhanced */}
      <div className="bg-gradient-to-r from-base-100 to-base-300 py-16 mb-12">
        <div className="container mx-auto text-center px-6">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Music Production?</h2>
          <p className="mb-8 text-lg max-w-2xl mx-auto">
            Join our early access program today and lock in our special rate of $7.50/month.
            <br />
            <span className="text-primary font-semibold">Limited time offer - Save 62.5%!</span>
          </p>
          <button 
            className="btn btn-primary btn-lg"
            onClick={() => navigate('/subscription-features')}
          >
            Get Started Now
          </button>
        </div>
      </div>

      {/* Add the footer at the bottom */}
      <LandingFooter />
    </div>
  );
};

export default LandingPage;
